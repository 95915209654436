export default function () {
    return {
        // copyright: `${websiteName}创新应用项目 ${productName}注册服务中`,
        copyright: `人工智能+创新应用服务平台 人工智能商城注册服务中心`,
        record: '京ICP备18024298号-53',
        concat: '400-652-1008',
        company: {
            // name: `${websiteName}创新应用项目 ${productName}注册服务中心`,
            name: `北京国信诚商互联网科技有限公司`,
            address: '北京市丰台区丰台科技园诺德中心11号楼310'
        }
    }
}