<script setup lang="ts">

const config = useConfig()
</script>

<template>
  <section class="footerFloor2">
    <glx-block container-class-name="glxBlockContainer">
      <div class="floor1Container">
        <div class="floor1">
          <div class="item">
            <span>版权所有：</span>
            <span>{{ config.copyright }}</span>
          </div>
          <div class="item record">
            <NuxtLink href='https://beian.miit.gov.cn' target='_blank'>
              <span>{{ config.record }}</span>
            </NuxtLink>
          </div>
          <div class="item">
            <span>联系我们：</span>
            <span>{{ config.concat }}</span>
          </div>
        </div>
      </div>
      <div class="floor2Container">
        <div class="floor2">
          <div class="item">
            <span>技术支持：</span>
            <span>{{ config.company.name }}</span>
          </div>
          <div class="item">
            <span>公司地址：</span>
            <span>{{ config.company.address }}</span>
          </div>
        </div>
      </div>
    </glx-block>
  </section>
</template>

<style scoped lang="scss">
.footerFloor2 {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background: rgba(#FFFFFF, 0.2);
  }

  :deep(.glxBlockContainer) {
    box-sizing: border-box;
    padding-top: 37px;
    height: 100%;
  }

  @mixin text() {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: rgba(#FFFFFF, 0.4);
    line-height: 1;
  }

  .floor1Container {
    display: flex;
    justify-content: center;
  }

  .floor1 {
    display: flex;

    > .item {
      @include text;

      &:nth-child(2) {
        margin-left: 74px;
      }

      &:nth-child(3) {
        margin-left: 74px;
      }

      &.record {
        > a {
          &:hover {
            color: #FF2A2A;
          }
        }
      }
    }
  }

  .floor2Container {
    display: flex;
    justify-content: center;
    margin-top: 27px;
  }

  .floor2 {
    display: flex;

    > .item {
      @include text;

      &:nth-child(2) {
        margin-left: 81px;
      }
    }
  }
}
</style>