<script setup lang="ts">
import type {NuxtLinkProps} from "#app/components/nuxt-link";
import {useHref} from "#imports";
import type {RouteLocationRaw} from "vue-router";

const route = useRoute()

const href = useHref()

type Menu = Omit<NuxtLinkProps, "href"> & { text: string, href: string }
const menuList: Array<Menu> = [
  {
    text: '首页',
    href: '/home'
  },
  {
    text: '关于我们',
    href: '/about',
  },
  {
    text: '资讯中心',
    href: '/news'
  },
  {
    text: '服务商',
    href: href.provider,
    target: '_blank'
  },
  {
    text: '扶持政策',
    href: '/policy'
  }
]

function isCurrent(href: RouteLocationRaw) {
  return route.matched.some(item => item.path === href)
}

const activeIndex = computed(() => {
  return menuList.findIndex(menu => isCurrent(menu.href))
})
const hoverIndex = ref<number | null>(null)
const itemMouseoverTimer = ref()

function onItemMouseover(index: number) {
  clearTimeout(itemMouseoverTimer.value)
  itemMouseoverTimer.value = setTimeout(() => {
    hoverIndex.value = index
  }, 10)
}

function onItemMouseout(index: number) {
  clearTimeout(itemMouseoverTimer.value)
  itemMouseoverTimer.value = setTimeout(() => {
    hoverIndex.value = null
  }, 10)
}

function menuIsActive(index: number) {
  return activeIndex.value === index || hoverIndex.value === index
}

function onClickLogo() {
  navigateTo({
    path: "/home"
  })
}
</script>

<template>
  <header>
    <section class="container">
      <div class="logoContainer" @click="onClickLogo">
        <div class="logoWrapper">
          <img class="logo" src="/img/logo.png" alt="logo"/>
        </div>
      </div>
      <nav>
        <ul>
          <template v-for="(item, index) in menuList">
            <li class="item" :class="{active:menuIsActive(index)}" @mouseover="onItemMouseover(index)"
                @mouseout="onItemMouseout(index)">
              <div class="linkWrapper" v-if="menuIsActive(index)">
                <nuxt-link :href="item.href" :target="item.target" class="link">{{ item.text }}</nuxt-link>
              </div>
              <nuxt-link :href="item.href" :target="item.target" class="link" v-else>{{ item.text }}</nuxt-link>
            </li>
          </template>
        </ul>
      </nav>
    </section>
  </header>
</template>

<style scoped lang="scss">
header {
  height: $header-height;

  > .container {
    @include glx-center-block;

    display: flex;

    .logoContainer {
      height: 100%;

      &:hover {
        cursor: pointer;
      }

      .logoWrapper {
        padding-top: 14px;

        .logo {

        }
      }
    }

    nav {
      margin-left: auto;

      ul {
        display: flex;
        height: 100%;

        li {
          @include glx-li;

          &.item {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #202024;
            line-height: 1;
            opacity: 0.6;
            padding-top: 33px;
            margin: 0 30px;

            .link {
            }

            &:hover {
              cursor: pointer;
            }

            &.active {
              font-weight: bold;
              font-size: 16px;
              color: #1628D1;
              line-height: 1;
              opacity: 1;

              padding-top: 24px;
              margin: 0 8px;

              > .linkWrapper {
                border-radius: 18px;
                border: 2px solid #1628D1;
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>