<script setup lang="ts">
const href = useHref()
</script>

<template>
  <section class="footerFloor1">
    <glx-block container-class-name="glxBlockContainer">
      <div class="content">
        <div class="logoContainer">
          <div class="logoWrapper">
            <NuxtLink href="/home">
              <img src="/img/logo白字.png" alt=''/>
            </NuxtLink>
          </div>
        </div>
        <div class="navListContainer">
          <div class="navList">
            <div class="navWrapper">
              <NuxtLink class="nav" href='/home'>首页</NuxtLink>
            </div>
            <div class="navWrapper">
              <NuxtLink class="nav" href='/about'>关于我们</NuxtLink>
            </div>
            <div class="navWrapper">
              <NuxtLink class="nav" href='/news'>资讯中心</NuxtLink>
            </div>
            <div class="navWrapper">
              <NuxtLink target='_blank' class="nav" :href="href.provider">服务商</NuxtLink>
            </div>
            <div class="navWrapper">
              <NuxtLink class="nav" href='/policy'>扶持政策</NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </glx-block>
  </section>
</template>

<style scoped lang="scss">
.footerFloor1 {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    background: rgba(#FFFFFF, 0.2);
  }

  :deep(.glxBlockContainer) {
    position: relative;

    .content {
      height: 100%;
      display: flex;
      box-sizing: border-box;
      align-items: stretch;
      width: 100%;
      max-width: $w1920;

      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);


      .logoContainer {
        width: 466px;
        flex-shrink: 0;

        display: flex;
        justify-content: flex-end;

        box-sizing: border-box;
        padding-right: 75px;
        padding-top: 44px;

        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 14px;
          bottom: 17px;
          width: 1px;
          background: rgba(#FFFFFF, 0.2);
        }

        .logoWrapper {
          opacity: 0.4;

          .logo {

          }
        }
      }

      .navListContainer {
        flex-grow: 1;
        padding-left: 173px;

        > .navList {
          display: flex;

          > .navWrapper {
            box-sizing: border-box;
            padding-top: 57px;

            &:not(:first-child) {
              margin-left: 125px;
            }

            > .nav {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: rgba(#FFFFFF, 0.4);
              line-height: 1;

              &:hover {
                //opacity: 1;
                color: #FF2A2A;
              }
            }
          }
        }
      }
    }
  }

}
</style>