<script setup lang="ts">

const {meta} = useAppConfig();

useHead({
  title: meta.title,
  meta: [
    {
      name: 'description',
      content: meta.description,
    },
    {
      name: 'keywords',
      content: meta.keywords
    }
  ]
})
</script>

<template>
  <main class="layout">
    <Header class="header"/>
    <section class="content">
      <slot/>
    </section>
    <Footer class="footer"/>
  </main>
</template>

<style scoped lang="scss">
.layout {
  min-height: 100vh;

  .header {
  }

  .content {
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
    display: flex;
    align-items: stretch;

    :deep(>section, >div,>main ) {
      flex-grow: 1;
    }
  }

  .footer {

  }
}
</style>