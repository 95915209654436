<script setup lang="ts">

</script>

<template>
  <footer class="float-end">
    <footer-floor1 class="floor1"/>
    <footer-floor2 class="floor2"/>
  </footer>
</template>

<style scoped lang="scss">
footer {
  background-color: #1F2453;

  > .floor1 {
    height: 130px;
  }

  > .floor2 {
    height: 130px;
  }
}
</style>